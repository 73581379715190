// src/hooks/useLeonGPT.js
import { useState, useEffect, useRef } from 'react';
import { sendMessageToAPI } from '../services/ApiService';

export const useLeonGPT = () => {
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const chatWindowRef = useRef(null); 

    useEffect(() => {
        const welcomeMessage = { role: 'bot', content: 'Hi I\'m Leon. Ask me anything you would like to know about me!' };
        setMessages([welcomeMessage]);
    }, []);

    const handleSend = async () => {
        if (inputText.trim() === '') return;
        if (isLoading) return;
    
        const userMessage = { role: 'user', content: inputText };
        setMessages(prevMessages => [...prevMessages, userMessage]); 
        setInputText('');
        setIsLoading(true);
    
        try {
            const botResponse = await sendMessageToAPI(inputText);
            const botMessage = { role: 'bot', content: botResponse };
            setMessages(prevMessages => [...prevMessages, botMessage]); 
        } catch (error) {
            const errorMessage = { role: 'bot', content: 'Something went wrong. Please try again.' };
            setMessages(prevMessages => [...prevMessages, errorMessage]); 
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        if (chatWindowRef.current) {
            chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
        }
    }, [messages]);

    return {
        messages,
        inputText,
        isLoading,
        chatWindowRef,
        setInputText,
        handleSend
    };
};
