import React from "react";
import "../styles/experiences.css";
import { experiences } from "../constants/Constants";

const Experiences = () => {
    return (
        <>
        <h2>Career</h2>
        <div className="roadmap-container">
           
            {experiences.map((exp, index) => (
                <div key={index} className="roadmap-item">
                    <div className="roadmap-step-details">
                        <span>
                            {exp.year} {exp.ongoing && <span className="ongoing">Ongoing</span>}
                        </span>
                        <h3>{exp.title}</h3>
                        <p>
                            {exp.description}
                            {exp.link ? (
                                <a id="comp_link" href={exp.link} target="_blank" rel="noopener noreferrer">
                                    {exp.company}
                                </a>
                            ) : exp.company}
                        </p>
                    </div>
                </div>
            ))}
        </div>
        </>
)};

export default Experiences;
