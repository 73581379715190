// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "./index.css";
import "./styles/landing.css";
import "./styles/about.css";
import "./styles/projects.css";
import "./styles/contact.css";

import {BrowserRouter} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
            <App />
    </BrowserRouter>
);
