import { CalculateAge } from "../services/Math";

export const personalDetails = {
    name: "Leon Burghardt",
    age: CalculateAge(),
    location: "near Dortmund, Germany",
    email: "contact@leon-burghardt.dev",
    availability: "Open for part time opportunities",
    brand:
    "“The computer was born to solve problems that did not exist before,” said Bill Gates, founder and former CEO of Microsoft. In this spirit, I navigate the evolving landscape of technology, where my academic endeavors in computer science and my passion projects in software development and artificial intelligence converge to address tomorrow’s challenges today."
      };

export const experiences = [
            {
                year: "since 2024",
                ongoing: true,
                title: "Software Developer",
                company: "Kleen Software GmbH",
                description: "Working in Software Development since February 2024 for ",
                link: "https://www.kleen-software.de/"
            },
            {
                year: "2023 - 2026",
                ongoing: true,
                title: "Bachelor of Science in Computer Science",
                company: "Technical University Dortmund",
                description: "Studying Computer Science since October 2023 at the ",
                link: "https://www.tu-dortmund.de/"
            },
            {
                year: "2010 - 2023",
                ongoing: false,
                title: "High School Diploma",
                company: "",
                description: "High School with specializations in Mathematics and Computer Science."
            },
        ];

    

