// src/components/TypewriterComponent.js
import React from 'react';
import Typewriter from 'typewriter-effect';
import { personalDetails } from '../constants/Constants';

const TypewriterComponent = ({ age }) => {
  return (
    <Typewriter
      options={{
        loop: true,
        deleteSpeed: 100,
        delay: 100,
      }}
      onInit={(typewriter) => {
        typewriter
          .typeString("Welcome to my Portfolio!")
          .pauseFor(1500)
          .deleteAll()
          .typeString(`I'm a ${personalDetails.age} year old computer science student & software developer.`)
          .pauseFor(1500)
          .deleteAll()
          .typeString("Feel free to chat with me using LeonGPT.")
          .pauseFor(1500)
          .deleteAll()
          .typeString("Discover projects, from web development to machine learning.")
          .pauseFor(1500)
          .deleteAll()
          .typeString("Engage with my journey through code and creativity.")
          .pauseFor(1500)
          .deleteAll()
          .start();
      }}
    />
  );
};

export default TypewriterComponent;
