// src/services/api.js
import axios from 'axios';

export const sendMessageToAPI = async (inputText) => {
    try {
        const response = await axios.post("/generate", {
            input_text: inputText,
        });
        return response.data.response;
    } catch (error) {
        console.error("API Error:", error);
        throw new Error('Something went wrong. Please try again.');
    }
};

export const startGame = async (nickname) => {
    try {
        const response = await fetch('/start', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ nickname })
        });
        if (!response.ok) {
            throw new Error('Failed to start game');
        }
        return await response.json();
    } catch (error) {
        console.error('Error starting game:', error);
        throw error;
    }
};

export const fetchLeaderboard = async () => {
    try {
        const response = await fetch('/leaderboard');
        if (!response.ok) {
            throw new Error('Failed to fetch leaderboard');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching leaderboard:', error);
        throw error;
    }
};

export const sendHighScore = async (sessionId, nickname, score) => {
    try {
        const response = await fetch('/leaderboard', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                session_id: sessionId,
                player_name: nickname,
                score: score
            })
        });
        if (!response.ok) {
            throw new Error('Failed to send high score');
        }
        return await response.json();
    } catch (error) {
        console.error('Error sending high score:', error);
        throw error;
    }
};

export const fetchGithubRepos = async () => {
    try {
        const response = await fetch(`https://api.github.com/users/leonburghardtdev/repos?type=public&sort=updated`);
        if (!response.ok) {
            throw new Error('Failed to fetch GitHub repositories');
        }
        return await response.json();
    } catch (error) {
        console.error("Failed to fetch projects", error);
        throw error;
    }
};

