// src/hooks/useChatBot.js
import { useState } from 'react';
import { sendMessageToAPI } from '../services/ApiService';

export const useAiChatWidget = () => {
    const [inputText, setInputText] = useState('');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [wasAsked, setWasAsked] = useState(false);

    const handleSend = async () => {
        if (inputText.trim() === '') return;

        setIsLoading(true);
        if (!wasAsked) {
            setWasAsked(true);
        }

        const userMessage = { role: 'user', content: inputText };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        setInputText('');

        try {
            const botResponse = await sendMessageToAPI(inputText);
            const botMessage = { role: 'bot', content: botResponse };

            const staticMessage = { role: 'bot', content: 'Click the button below to chat more with me!' };
            setMessages(prevMessages => [...prevMessages, botMessage, staticMessage]);

        } catch (error) {
            const errorMessage = { role: 'bot', content: error.message };
            setMessages(prevMessages => [...prevMessages, errorMessage]);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        inputText,
        setInputText,
        messages,
        isLoading,
        wasAsked,
        handleSend,
    };
};
