import Header from "./components/Header";
import { useEffect } from 'react';
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {

  useEffect(() => {
    const updateVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Führe die Funktion einmal beim Laden der Seite aus
    updateVh();

    // Aktualisiere die Höhe bei Änderungen der Fenstergröße
    window.addEventListener('resize', updateVh);

    return () => {
      window.removeEventListener('resize', updateVh);
    };
  }, []);

  return (
    <div id="root">
      <Header />
      <AnimatedRoutes />
    </div>
  );
}

export default App;
