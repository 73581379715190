import React from 'react';
import SocialIcons from '../components/SocialIcons';
import AiChatWidget from '../components/AiChatWidget';
import TypewriterComponent from '../components/TypewriterComponent';
import '../styles/landing.css';
import { personalDetails } from '../constants/Constants';

const Landing = () => {
    return (
        <section className="landing">
            <div className="textContainer">
                <h1 className="name">{personalDetails.name}</h1>
                <div className="description">
                    <TypewriterComponent /> 
                </div>
                <AiChatWidget />
            </div>
            <div className="socialIconsLanding">
                <SocialIcons />
            </div>
        </section>
    );
};

export default Landing;
