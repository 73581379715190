// src/components/BugBouncer.js
import React from 'react';
import PageHeader from "../components/PageHeader";
import { useBugBouncer } from '../hooks/useBugBouncer';
import '../styles/bug-bouncer.css';

const BugBouncer = () => {
    const {
        gameRef,
        player,
        platforms,
        gameOver,
        gameStarted,
        leaderboard,
        nickname,
        isMobileView,
        showLeaderboardManually,
        highScore,
        handleStartGame,
        resetGame,
        handleShowLeaderboardManually,
        setNickname
    } = useBugBouncer();

    return (
        <section className='game'>
            <PageHeader title="Bug Bouncer" description="" />
            <p className="game-description">A small web-based version of the popular game "Doodle Jump", built with React.js to showcase my web development skills.</p>

            {!isMobileView ? (
                <div className="game-wrapper">
                    {gameOver || showLeaderboardManually && !gameStarted ? (
                        <div className="leaderboard">
                            <h2>Leaderboard</h2>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Rank</th>
                                        <th>Player</th>
                                        <th>High Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard.map((entry, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{entry.player_name}</td>
                                            <td>{entry.score}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : null}
                    <div className="game-container" ref={gameRef}>
                        {!gameStarted ? (
                            <div className="start-screen">
                                <h1>Jump In!</h1>
                                <input
                                    type="text"
                                    placeholder="Enter your nickname"
                                    value={nickname}
                                    onChange={(e) => setNickname(e.target.value)}
                                    maxLength={7}
                                />
                                <button onClick={handleStartGame} disabled={!nickname.trim()}>Start Game</button>
                                {!showLeaderboardManually ? (
                                    <button onClick={handleShowLeaderboardManually}>Show Leaderboard</button>
                                ) : (
                                    <button onClick={handleShowLeaderboardManually}>Hide Leaderboard</button>
                                )}
                            </div>
                        ) : gameOver ? (
                            <div className="game-over">
                                <h1>Game Over</h1>
                                <p>Highscore: {highScore}</p>
                                <button onClick={resetGame}>Play Again</button>
                            </div>
                        ) : (
                            <>
                                <div className="player" style={{ left: `${player.x}px`, top: `${player.y}px` }}></div>
                                {platforms.map((platform, index) => (
                                    <div key={index} className="platform" style={{ left: `${platform.x}px`, top: `${platform.y}px` }}></div>
                                ))}
                                <div className="highscore">Highscore: {highScore}</div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="mobile-game">
                    <div className="mobile-content">
                        {!gameStarted && !showLeaderboardManually ? (
                            <div className="start-screen">
                                <h1>Jump In!</h1>
                                <input
                                    type="text"
                                    placeholder="Enter your nickname"
                                    value={nickname}
                                    onChange={(e) => setNickname(e.target.value)}
                                    maxLength={7}
                                />
                                <button onClick={handleStartGame} disabled={!nickname.trim()}>Start Game</button>
                                {!showLeaderboardManually ? (
                                    <button onClick={handleShowLeaderboardManually}>Show Leaderboard</button>
                                ) : (
                                    <button onClick={handleShowLeaderboardManually}>Hide Leaderboard</button>
                                )}
                            </div>
                        ) : gameOver && !showLeaderboardManually ? (
                            <div className="game-over">
                                <h1>Game Over</h1>
                                <p>Highscore: {highScore}</p>
                                <button onClick={resetGame}>Play Again</button>
                            </div>
                        ) : !showLeaderboardManually && (
                            <div className="mobile-game-container">
                                <div className="game-content">
                                    <div className="player" style={{ left: `${player.x}px`, top: `${player.y}px` }}></div>
                                    {platforms.map((platform, index) => (
                                        <div key={index} className="platform" style={{ left: `${platform.x}px`, top: `${platform.y}px` }}></div>
                                    ))}
                                    <div className="highscore">Highscore: {highScore}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    {showLeaderboardManually && (
                        <>
                            <div className="leaderboard">
                                <h2>Leaderboard</h2>
                                <div className="scrollable-leaderboard">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Rank</th>
                                                <th>Player</th>
                                                <th>High Score</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leaderboard.map((entry, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{entry.player_name}</td>
                                                    <td>{entry.score}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <button className="back-button" onClick={handleShowLeaderboardManually}>Back</button>
                        </>
                    )}              
                </div>
            )}
        </section>
    );
};

export default BugBouncer;
