import { Routes, Route, useLocation } from "react-router-dom";

import Landing from "../pages/Landing";
import About from "../pages/About";
import Projects from "../pages/Projects";
import Contact from "../pages/Contact";
import BugBouncer from "../pages/BugBouncer";
import LeonGPT from "../pages/LeonGPT";

const AnimatedRoutes = () => {
  const location = useLocation(); 
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Landing />} />
      <Route path="/leon-gpt" element={<LeonGPT />} />
      <Route path="/about" element={ <About/>}/>
      <Route path="/bug-bouncer" element={<BugBouncer />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contact" element={  <Contact /> }/>
    </Routes>
  );
};

export default AnimatedRoutes;
