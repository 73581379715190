import { NavLink } from "react-router-dom";

const NavLinks = ({ isMobile, closeMenu }) => {
  return (
    <div className={`nav-links ${isMobile ? "mobile" : ""}`}>
      <NavLink to="/" onClick={isMobile ? closeMenu : null}>
        Home
      </NavLink>
      <NavLink to="/about" onClick={isMobile ? closeMenu : null}>
        About
      </NavLink>
      <NavLink to="/leon-gpt" onClick={isMobile ? closeMenu : null}>
        LeonGPT
      </NavLink>
      <NavLink to="/bug-bouncer" onClick={isMobile ? closeMenu : null}>
        Bug Bouncer
      </NavLink>
      <NavLink to="/projects" onClick={isMobile ? closeMenu : null}>
        Projects
      </NavLink>
      <NavLink to="/contact" onClick={isMobile ? closeMenu : null}>
        Contact
      </NavLink>
    </div>
  );
};

export default NavLinks;
