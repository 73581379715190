import PageHeader from "../components/PageHeader";
import SocialIcons from "../components/SocialIcons";
import ContactInfo from "../components/ContactInfo";
import "../styles/contact.css";

const Contact = () => {
  return (
    <section className="contact">
      <PageHeader title="Contact" description="" />
        <div className="contactWrap container">
            <div className="row">
                <ContactInfo />
            </div>
        </div>
      <SocialIcons />
    </section>
  );
};

export default Contact;
