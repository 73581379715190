// src/components/AiChatWidget.js
import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useAiChatWidget } from '../hooks/useAiWidget';
import '../styles/aichatwidget.css';
import userIcon from '../assets/images/user-pfp.png';  
import botIcon from '../assets/images/ai-pfp.png';  

const AiChatWidget = () => {
    const { inputText, setInputText, messages, isLoading, wasAsked, handleSend } = useAiChatWidget();
    const navigate = useNavigate();

    const handleChatWithMe = () => {
        navigate('/leon-gpt'); 
    };

    return (
        <div className="leon-chat-widget-container">
            <div className="chatbot-window">
                <h2 className="chatbot-widget-title">Chat with LeonGPT!</h2>
                {!wasAsked && !isLoading && (
                    <div className={`chatbot-input-bar${isLoading ? ' loading' : ''}`}>
                        <input
                            type="text"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Ask anything about me..."
                            className="chatbot-input-field"
                            onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                        />
                        <button onClick={handleSend} className="chatbot-send-button" disabled={isLoading}>
                            Send
                        </button>
                    </div>
                )}
                {messages.map((msg, index) => (
                    <div key={index} className={`chatbot-message-container ${msg.role}`}>
                        <img 
                            src={msg.role === 'user' ? userIcon : botIcon} 
                            alt={`${msg.role} icon`} 
                            className={msg.role === 'user' ? "chatbot-message-icon-user" : "chatbot-message-icon-bot"}
                        />
                        <div className={`chatbot-message chatbot-message-${msg.role}`}>
                            <p>{msg.content}</p>
                        </div>
                    </div>
                ))}
                {wasAsked && !isLoading && (
                    <button onClick={handleChatWithMe} className="chatbot-chat-button">
                        Chat with me!
                    </button>
                )}
                {isLoading && (
                    <div className="loading-indicator"></div>
                )}
            </div>
        </div>
    );
};

export default AiChatWidget;
