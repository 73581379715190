// src/hooks/useProjects.js
import { useEffect, useState } from "react";
import { fetchGithubRepos } from  '../services/ApiService';

export const useProjects = () => {
    const [projects, setProjects] = useState([]);
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadProjects = async () => {
            setIsLoading(true);
            try {
                const repos = await fetchGithubRepos();
                const projectData = repos.map(repo => ({
                    id: repo.id,
                    name: repo.name,
                    lastUpdated: new Date(repo.updated_at).toLocaleDateString("en-US"),
                    link: repo.html_url,
                    description: repo.description,
                    type: 'github'
                }));

                const manualProjects = [
                    /* 
                    {
                        id: 'gp-1',
                        name: "Bundesliga360 GooglePlay App",
                        lastUpdated: "08/31/2023",
                        link: "https://play.google.com/store/apps/details?id=xyz.tr3x.bundesliga360",
                        type: 'googleplay'
                    },
                    {
                        id: 'gp-2',
                        name: "TaskTarea GooglePlay App",
                        lastUpdated: "30/12/2022",
                        link: "https://play.google.com/store/apps/details?id=xyz.tr3x.TaskTarea",
                        type: 'googleplay'
                    },
                    {
                        id: 'gp-3',
                        name: "StackCO GooglePlay App",
                        lastUpdated: "08/04/2022",
                        link: "https://play.google.com/store/apps/details?id=com.tr3xTech.StackCO",
                        type: 'googleplay'
                    }
                         */
                ];
               

                const combinedProjects = [...projectData, ...manualProjects].sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
                setProjects(combinedProjects);
            } catch (error) {
                console.error("Failed to load projects", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadProjects();
    }, []);

    const handleNext = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    };

    const handlePrev = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
    };

    return {
        projects,
        currentProjectIndex,
        isLoading,
        handleNext,
        handlePrev,
        setCurrentProjectIndex
    };
};
