// src/components/Projects.js
import React from "react";
import SocialIcons from "../components/SocialIcons";
import '../styles/projects.css';
import leftArrow from '../assets/images/left-arrow.png';
import rightArrow from '../assets/images/right-arrow.png';
import { useProjects } from '../hooks/useProjects';

const Projects = () => {
    const {
        projects,
        currentProjectIndex,
        isLoading,
        handleNext,
        handlePrev,
        setCurrentProjectIndex
    } = useProjects();

    return (
        <div className="portfolio">
            <h3 className="pageTitle">Projects</h3>
            <div className="projects-carousel">
                {isLoading ? (
                    <div className="loader"></div>
                ) : (
                    <div className="carousel-wrapper">
                        <button className="carousel-btn left-btn" onClick={handlePrev}>
                            <img src={leftArrow} alt="Previous" />
                        </button>
                        <div className="project-card">
                            <h4>{projects[currentProjectIndex]?.name}</h4>
                            <p>{projects[currentProjectIndex]?.description || "No description available."}</p>
                            <p className="last-updated">Last Updated: {projects[currentProjectIndex]?.lastUpdated}</p>
                            <a href={projects[currentProjectIndex]?.link} className="project-link" target="_blank" rel="noopener noreferrer">
                                More
                            </a>
                        </div>
                        <button className="carousel-btn right-btn" onClick={handleNext}>
                            <img src={rightArrow} alt="Next" />
                        </button>
                    </div>
                )}
                <div className="mobile-navigation">
                    <button className="mobile-nav-btn left-btn" onClick={handlePrev}>
                        <img src={leftArrow} alt="Previous" />
                    </button>
                    <button className="mobile-nav-btn right-btn" onClick={handleNext}>
                        <img src={rightArrow} alt="Next" />
                    </button>
                </div>
            </div>
            <div className="pagination-dots">
                {projects.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentProjectIndex ? 'active' : ''}`}
                        onClick={() => setCurrentProjectIndex(index)}
                    ></span>
                ))}
            </div>
            <SocialIcons />
        </div>
    );
};

export default Projects;
