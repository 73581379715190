import AboutMe from "../components/AboutMe";
import PageHeader from "../components/PageHeader";
import Experiences from "../components/Experiences";
import SocialIcons from "../components/SocialIcons";
import "../styles/about.css";

const About = () => {
  
return (
    <section className="about">
      <PageHeader title="About Me" description="" />
      <AboutMe />
        <Experiences />
        <div className="mobileSocialIcons">
          <SocialIcons />
        </div>
        
    </section>
  );
};

export default About;
