import { useState, useEffect, useRef, useCallback } from 'react';
import { startGame, fetchLeaderboard, sendHighScore } from '../services/ApiService';

export const useBugBouncer = () => {
    const gameRef = useRef(null);
    const [player, setPlayer] = useState({ x: 150, y: 400 });
    const velocity = useRef({ x: 0, y: -8 });
    const [platforms, setPlatforms] = useState([]);
    const [cameraOffset, setCameraOffset] = useState(0);
    const [sessionId, setSessionId] = useState('');
    const [targetCameraOffset, setTargetCameraOffset] = useState(0);
    const [lastPlayerY, setLastPlayerY] = useState(400);
    const [highScore, setHighScore] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [gameStarted, setGameStarted] = useState(false); // CHANGE TO FALSE BEFORE BUILDING
    const [leaderboard, setLeaderboard] = useState([]);
    const [nickname, setNickname] = useState('');
    const [showLeaderboardManually, setShowLeaderboardManually] = useState(false);
    const [lastPlatformY, setLastPlatformY] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);
    const gravity = 0.2;
    const totalPlatforms = 250;
    const gameWidth = 300;

    useEffect(() => {
        const checkDeviceSize = () => {
            const width = window.innerWidth;
            setIsMobileView(width < 600);
        };

        checkDeviceSize();
        window.addEventListener('resize', checkDeviceSize);

        return () => {
            window.removeEventListener('resize', checkDeviceSize);
        };
    }, []);

    useEffect(() => {
        const savedSessionId = getCookie('session_id');
        const savedNickname = getCookie('nickname');
        if (savedSessionId) {
            setSessionId(savedSessionId);
            setNickname(savedNickname);
            setGameStarted(false);
        }
    }, []);

    useEffect(() => {
        if (gameStarted) {
            fetchLeaderboard().then(setLeaderboard).catch(console.error);
        }
    }, [gameStarted]);

    const generatePlatforms = useCallback((numberOfPlatforms = 250) => {
        const newPlatforms = [];
        let lastPlatformY = 500;
        for (let i = 0; i < numberOfPlatforms; i++) {
            const platformX = Math.random() * 240;
            const platformY = lastPlatformY - Math.random() * 80 - 50;
            newPlatforms.push({ x: platformX, y: platformY });
            lastPlatformY = platformY;
        }
        return newPlatforms;
    }, []);

    const resetGame = useCallback(() => {
        setPlayer({ x: 150, y: 400 });
        velocity.current = { x: 0, y: -8 };
        setCameraOffset(0);
        setTargetCameraOffset(0);
        setLastPlayerY(400);
        setHighScore(0);
        setGameOver(false);
        const initialPlatforms = generatePlatforms(totalPlatforms);
        setPlatforms(initialPlatforms);
    }, [generatePlatforms, totalPlatforms]);

    useEffect(() => {
        resetGame();
    }, [resetGame]);

    const sendHighScoreToServer = useCallback(() => {
        if (!sessionId) return;
        sendHighScore(sessionId, nickname, highScore)
            .then(() => fetchLeaderboard().then(setLeaderboard))
            .catch(console.error);
    }, [sessionId, nickname, highScore]);

    useEffect(() => {
        const gameLoop = setInterval(() => {
            if (!gameStarted || gameOver) return;

            setPlayer((p) => {
                const newVelocityY = velocity.current.y + gravity;
                const newY = p.y + newVelocityY;
                let newX = p.x + velocity.current.x;

                if (newX < 0) newX = gameWidth - 20;
                if (newX + 20 > gameWidth) newX = 0;

                if (newY > 600) {
                    setGameOver(true);
                    sendHighScoreToServer();
                }

                let onPlatform = false;
                let collidedPlatform = null;

                platforms.forEach((platform) => {
                    if (
                        newX + 20 > platform.x &&
                        newX < platform.x + 60 &&
                        p.y + 20 <= platform.y &&
                        p.y + 20 + newVelocityY > platform.y &&
                        velocity.current.y > 0
                    ) {
                        onPlatform = true;
                        collidedPlatform = platform;
                    }
                });

                if (onPlatform && collidedPlatform) {
                    velocity.current.y = -8; 

                    if (lastPlatformY !== collidedPlatform.y) {
                        setHighScore((prevScore) => prevScore + 1);
                        setLastPlatformY(collidedPlatform.y);
                    }

                    const heightDifference = lastPlayerY - collidedPlatform.y;
                    if (collidedPlatform.y < lastPlayerY) {
                        setTargetCameraOffset((prevOffset) => prevOffset + heightDifference);
                        setLastPlayerY(collidedPlatform.y);
                    }

                    return { ...p, x: newX, y: collidedPlatform.y - 20 };
                }

                if (newY < 200) {
                    const differenceToCenter = 200 - newY;
                    setTargetCameraOffset((prevOffset) => prevOffset + differenceToCenter);
                }

                return { x: newX, y: newY };
            });

            velocity.current.y += gravity;

            const smoothMoveSpeed = 2;
            if (cameraOffset < targetCameraOffset) {
                setCameraOffset((offset) => Math.min(offset + smoothMoveSpeed, targetCameraOffset));
            }

            if (cameraOffset < targetCameraOffset) {
                const offsetChange = smoothMoveSpeed;
                setPlayer((p) => ({ ...p, y: p.y + offsetChange }));
                setPlatforms((ps) =>
                    ps.map((platform) => ({ ...platform, y: platform.y + offsetChange }))
                );
            }

            setPlatforms((ps) =>
                ps.map((platform) => {
                    if (platform.y > 600) {
                        return { ...platform, y: platform.y - totalPlatforms * 80 };
                    }
                    return platform;
                })
            );
        }, 20);

        return () => clearInterval(gameLoop);
    }, [platforms, cameraOffset, targetCameraOffset, lastPlayerY, highScore, gameStarted, gameOver, lastPlatformY, sendHighScoreToServer]);

    // Keyboard and Device Motion Controls
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (!gameStarted || gameOver) return;

            switch (event.key) {
                case 'ArrowLeft':
                case 'a':
                case 'A':
                    velocity.current.x = -4; // Move left
                    break;
                case 'ArrowRight':
                case 'd':
                case 'D':
                    velocity.current.x = 4; // Move right
                    break;
                default:
                    break;
            }
        };

        const handleKeyUp = (event) => {
            if (!gameStarted || gameOver) return;

            switch (event.key) {
                case 'ArrowLeft':
                case 'a':
                case 'A':
                case 'ArrowRight':
                case 'd':
                case 'D':
                    velocity.current.x = 0; // Stop horizontal movement when the key is released
                    break;
                default:
                    break;
            }
        };

        const handleDeviceOrientation = (event) => {
            if (!isMobileView || !gameStarted || gameOver) return;

            const { gamma } = event; // gamma represents the left-to-right tilt of the device in degrees
            const movementSpeed = gamma / 10; // Adjust sensitivity if needed
            velocity.current.x = movementSpeed;
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);
        window.addEventListener('deviceorientation', handleDeviceOrientation);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            window.removeEventListener('deviceorientation', handleDeviceOrientation);
        };
    }, [gameStarted, gameOver, isMobileView]);

    const handleStartGame = () => {
        if (nickname.trim() === '') return;

        startGame(nickname)
            .then((data) => {
                setSessionId(data.session_id);
                setCookie('session_id', data.session_id, 365);
                setCookie('nickname', nickname, 365);
                setGameStarted(true);
            })
            .catch(console.error);
    };

    const handleShowLeaderboardManually = () => {
        setShowLeaderboardManually(!showLeaderboardManually);
        if (!showLeaderboardManually) {
            fetchLeaderboard().then(setLeaderboard).catch(console.error);
        }
    };

    return {
        gameRef,
        player,
        platforms,
        cameraOffset,
        gameOver,
        gameStarted,
        leaderboard,
        nickname,
        isMobileView,
        showLeaderboardManually,
        highScore,
        handleStartGame,
        resetGame,
        handleShowLeaderboardManually,
        setNickname
    };
};

const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/; SameSite=Lax`;
};

const getCookie = (name) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
};
