// src/components/LeonGPT.js
import React, { useEffect, useRef } from 'react';  
import PageHeader from '../components/PageHeader';
import { useLeonGPT } from '../hooks/useLeonGPT';
import userIcon from '../assets/images/user-pfp.png';  
import botIcon from '../assets/images/ai-pfp.png';   
import '../styles/leon-gpt.css';

const LeonGPT = () => {
    const {
        messages,
        inputText,
        isLoading,
        chatWindowRef,
        setInputText,
        handleSend
    } = useLeonGPT();

    const lastMessageRef = useRef(null);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <div className="leon-gpt-container">
            <PageHeader title="LeonGPT" description="" />
            <p className="gpt-description">An artificial intelligence built on OpenAI's GPT-2 117M model, trained specifically to provide information about me, demonstrating my skills in artificial intelligence.</p>
            <div class="chat-wrapper">
            <div className="chat-window-leon-gpt" ref={chatWindowRef}>
                {messages.map((msg, index) => (
                    <div key={index} className={`message-container ${msg.role}`}>
                        <img 
                            src={msg.role === 'user' ? userIcon : botIcon} 
                            alt={`${msg.role} icon`} 
                            className={msg.role === 'user' ? "message-icon-user" : "message-icon-bot"}
                        />
                        <div className={`message ${msg.role}`}>
                            <p>{msg.content}</p>
                        </div>
                    </div>
                ))}
                {isLoading && <div className="loading-circle"></div>}
            </div>

            {/* Desktop input bar */}
            <div className="input-bar-desktop">
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Ask me anything about Leon..."
                    className="chatbot-input"
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <button onClick={handleSend} className="chatbot-send-btn" disabled={isLoading}>
                    Send
                </button>
            </div>
            </div>

            {/* Mobile input bar */}
            <div className="input-bar-mobile">
                <input
                    type="text"
                    value={inputText}
                    onChange={(e) => setInputText(e.target.value)}
                    placeholder="Ask me anything about Leon..."
                    className="chatbot-input-mobile"
                    onKeyDown={(e) => e.key === 'Enter' && handleSend()}
                />
                <button onClick={handleSend} className="chatbot-send-btn-mobile" disabled={isLoading}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default LeonGPT;
