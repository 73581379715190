import { useState } from "react";
import NavLinks from "./NavLinks";
import "../styles/header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <a className="logo" />
      <div className={`hamburger ${isOpen ? "open" : ""}`} onClick={toggleMobileMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <nav className={`mobile-nav ${isOpen ? "open" : ""}`}>
        <NavLinks isMobile={true} closeMenu={() => setIsOpen(false)} />
      </nav>
      <nav className="links">
        <NavLinks isMobile={false} />
      </nav>
    </header>
  );
};

export default Header;
